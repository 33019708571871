define("discourse/plugins/discourse-data-explorer/discourse/controllers/group-reports-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/components/modal/bookmark", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/bookmark", "discourse/models/bookmark", "discourse-common/utils/decorators"], function (_exports, _tracking, _controller, _object, _service, _bookmark, _ajax, _ajaxError, _bookmark2, _bookmark3, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GroupReportsShowController = _exports.default = (_class = class GroupReportsShowController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "showResults", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
      _initializerDefineProperty(this, "results", _descriptor5, this);
      _initializerDefineProperty(this, "queryGroupBookmark", _descriptor6, this);
      _defineProperty(this, "explain", false);
    }
    get hasParams() {
      return this.model.param_info.length > 0;
    }
    get bookmarkLabel() {
      return this.queryGroupBookmark ? "bookmarked.edit_bookmark" : "bookmarked.title";
    }
    get bookmarkIcon() {
      if (this.queryGroupBookmark && this.queryGroupBookmark.reminder_at) {
        return _bookmark3.WITH_REMINDER_ICON;
      }
      return _bookmark3.NO_REMINDER_ICON;
    }
    get bookmarkClassName() {
      return this.queryGroupBookmark ? ["query-group-bookmark", "bookmarked"].join(" ") : "query-group-bookmark";
    }
    async run() {
      this.loading = true;
      this.showResults = false;
      try {
        const response = await (0, _ajax.ajax)(`/g/${this.get("group.name")}/reports/${this.model.id}/run`, {
          type: "POST",
          data: {
            params: JSON.stringify(this.model.params),
            explain: this.explain
          }
        });
        this.results = response;
        if (!response.success) {
          return;
        }
        this.showResults = true;
      } catch (error) {
        if (error.jqXHR?.status === 422 && error.jqXHR.responseJSON) {
          this.results = error.jqXHR.responseJSON;
        } else {
          (0, _ajaxError.popupAjaxError)(error);
        }
      } finally {
        this.loading = false;
      }
    }
    toggleBookmark() {
      const modalBookmark = this.queryGroupBookmark || this.store.createRecord("bookmark", {
        bookmarkable_type: "DiscourseDataExplorer::QueryGroup",
        bookmarkable_id: this.queryGroup.id,
        user_id: this.currentUser.id
      });
      return this.modal.show(_bookmark.default, {
        model: {
          bookmark: new _bookmark2.BookmarkFormData(modalBookmark),
          afterSave: savedData => {
            const bookmark = this.store.createRecord("bookmark", savedData);
            this.queryGroupBookmark = bookmark;
            this.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());
          },
          afterDelete: () => {
            this.queryGroupBookmark = null;
          }
        }
      });
    }

    // This is necessary with glimmer's one way data stream to get the child's
    // changes of 'params' to bubble up.
    updateParams(identifier, value) {
      this.set(`model.params.${identifier}`, value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.model.results;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "queryGroupBookmark", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.queryGroup?.bookmark;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "run", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "run"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBookmark", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBookmark"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateParams"), _class.prototype)), _class);
});